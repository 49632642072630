import AppRouters from "./routers/AppRouters";
import "antd/dist/antd.css";
import 'firebase/firestore';


function App() {
  return (<AppRouters />)
}

export default App;
